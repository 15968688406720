import Storage from "@/util/Storage";
import {addModuleAdmin, updateModuleAdmin} from "@/Api/Platform/bascs";
import {Toast} from "vant";

// 添加功能菜单
export function addModule(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    Toast.loading({ duration:0,message:"添加中~~~" })
    return Promise.resolve(addModuleAdmin({userId,token},body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 修改功能菜单
export function updateModule(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const keys = Object.keys(body);
    let num = 0
    // console.log(keys,oldData)
    keys.forEach(item=>{ // body[item] != null && ((body[item]+'').length < 1) ||
        if ( body[item] && oldData[item] != null && body[item] != oldData[item] ) num += 1;
    })
    if (num<1){
        Toast("请修改内容")
        return Promise.reject()
    }

    body.id = oldData.id
    body.menuType = oldData.menuType || ''
    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateModuleAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
