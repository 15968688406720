

export const typeList = [
    {
        title:"跳转到H5",
        state:"0"
    },{
        title:"拨打电话",
        state:"1"
    },{
        title:"跳转原生",
        state:"2"
    }
]

export const selectType = {
    "0":"H5",
    "1":"callPhone",
    "2":"origin",
}


export const keyData = [
    "name",
    "code",
    "sort",
    "imgUrl",
    // "netUrl",
    "phone",
    "sort",
    "isShow",
    "remark",
    "androidNetUrl",
    "iosNetUrl",
    "gzhNetUrl",
    "xcxNetUrl",
]
